.argument {
  margin-top: var(--margin-m);
  margin-bottom: var(--margin-m);

  display: flex;
  gap: var(--grid-gap);

  flex-flow: wrap;
  @media (--media-md) {
    flex-wrap: nowrap;
  }

  &__content {
    position: relative;
    flex: 1 1 var(--c7);
  }

  &__figure {
    flex: 1 1 var(--c5);
    &.right {
      img {
        margin-left: auto;
      }
    }
  }

  &__text {
    background-color: var(--argument-bg);
    color: var(--argument-color);

    padding: var(--spacing);
    & *:first-child {
      margin-top: 1rem;
      margin-bottom: 1em;
    }
    & *:last-child {
      margin-bottom: 1em;
    }
  }

  &__index {
    --_size: 1.5em;
    width: var(--_size);
    height: var(--_size);

    background-color: var(--argument-index-bg);
    color: var(--argument-index-color);
    font-size: 2.6em;
    font-weight: 700;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    left: calc(var(--spacing) / 2.6);
    top: calc(var(--spacing) * -0.61538);
  }
}
