.engagement-boxes {
  max-width: var(--w);
  --grid-cell: var(--c4);
}

.engagement-box {
  padding: var(--spacing);
  display: flex;
  flex-direction: column;
  gap: var(--spacing);

  background: var(--engagement-bg);
  color: var(--engagement-color);
  --icon-color: var(--engagement-color);

  &.box-0 {
    background: var(--engagement-bg-0);
    color: var(--engagement-color-0);
    --icon-color: var(--engagement-color-0);
  }

  &.box-1 {
    background: var(--engagement-bg-1);
    color: var(--engagement-color-1);
    --icon-color: var(--engagement-color-1);
  }

  &.box-2 {
    background: var(--engagement-bg-2);
    color: var(--engagement-color-2);
    --icon-color: var(--engagement-color-2);
  }

  &.box-3 {
    background: var(--engagement-bg-3);
    color: var(--engagement-color-3);
    --icon-color: var(--engagement-color-3);
  }

  &.box-4 {
    background: var(--engagement-bg-4);
    color: var(--engagement-color-4);
    --icon-color: var(--engagement-color-4);
  }
}

.engagement-box__button {
  /* text-align: center; */
  margin-top: auto;
}

.engagement-box__some-links {
  font-size: var(--interact-size);
  margin-top: auto;
  display: flex;
  gap: var(--grid-gap);
  .some-link {
    display: flex;
  }
}
