footer {
  background: var(--footer-bg);
  color: var(--footer-color);

  --icon-color: var(--footer-color);

  padding-top: var(--margin-m);
  padding-bottom: var(--margin-m);

  .footer-container {
    max-width: var(--w);
    display: flex;
    flex-flow: row wrap;
    gap: var(--margin);

    align-items: flex-start;
  }

  .logo-container {
    flex: 0 1 var(--c2);
  }

  .contact-container {
    flex: 1 1 var(--c4);

    --icon-size: 2rem;

    display: flex;
    flex-direction: column;
    gap: var(--spacing);
  }

  .footer-nav {
    border-top: 1px solid var(--footer-color);
    .nav-list {
      margin: var(--margin) auto;

      gap: var(--grid-gap);
      justify-content: center;
      flex-flow: wrap;
    }
  }

  .nav-container {
    flex: 0 1 100%;
  }

  .contact-link {
    display: flex;
    align-items: center;
    gap: 0.5em;

    margin-bottom: 0.25rem;

    &:last-of-type {
      margin-bottom: 0px;
    }
  }

  .some-links-container {
    font-size: var(--interact-size);

    margin: 0 auto;

    flex: 0 1 0%;
    align-self: flex-end;

    display: flex;
    gap: var(--spacing);
    justify-content: right;
  }

  .nav-link {
    padding: 0.5em 0;
  }

  .nav-list-item {
    border-bottom: 1px solid transparent;
    &:hover,
    &:focus-visible,
    &.active {
      border-bottom: 1px solid;
    }
  }
}

footer {
  border-top: 1px solid var(--black);
}
