.button {
  -webkit-appearance: none; /* iOS fix */

  display: inline-flex;
  align-items: center;
  gap: var(--grid-gap);
  /* border-radius: 0.5em; */
  padding: 0.5rem 1rem;
  cursor: pointer;

  font-size: var(--interact-size);

  text-decoration: none;
  font-weight: 700;

  color: var(--button-color);

  background: var(--button-bg);
  border: 0.15em solid var(--button-bg);

  .icon {
    fill: var(--button-color);
  }

  &:hover,
  &:focus-visible {
    color: var(--button-bg);
    background: var(--button-color);
    border-color: var(--button-bg);

    .icon {
      fill: var(--button-bg);
    }
  }
}

/* theme special case for engagement-box */
.button--inverted {
  color: var(--button-bg);
  background: var(--button-color);
  border-color: var(--button-color);

  .icon {
    fill: var(--button-bg);
  }

  &:hover,
  &:focus-visible {
    color: var(--button-color);
    background: var(--button-bg);
    border-color: var(--button-color);

    .icon {
      fill: var(--button-color);
    }
  }
}

.button--header {
  color: var(--header-button-color);
  background: var(--header-button-bg);
  border-color: var(--header-button-color);
  font-size: 1.5rem;
  border-width: 2px;
  padding: 1rem 2rem;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);

  @media (--media-sm-only) {
    padding: 0.75rem 1rem;
  }

  .icon {
    fill: var(--header-button-color);
  }

  &:hover,
  &:focus-visible {
    color: var(--header-button-bg);
    background: var(--header-button-color);
    border-color: var(--header-button-color);

    .icon {
      fill: var(--header-button-bg);
    }
  }
}

.button--special {
  --button-color: var(--white);
  --button-bg: var(--black);
  border-color: var(--black);
  --icon-color: var(--white);
  /* border-radius: 2em; */
  padding: 1.2em 2em;
}

.button--clean {
  background: none;
  border: none;
  color: var(--button-bg);
  .icon {
    fill: var(--button-bg);
  }
  &:hover,
  &:focus-visible {
    background: none;
    border: none;
    color: var(--button-bg);
    .icon {
      fill: var(--button-bg);
    }
  }
}

.button--menu-toggle {
  --icon-size: 56px;
  --toggle-bg: var(--button-bg);
  --toggle-color: var(--button-color);
  max-height: var(--icon-size);
  padding: 0;

  @media (--media-sm-only) {
    --icon-size: 38px;
  }
  .icon {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  }

  &:hover,
  &:focus-visible {
    --toggle-bg: var(--button-color);
    --toggle-color: var(--button-bg);
  }
}

.button--arrow,
.button--download,
.button--arrow-back {
  --icon-size: 1.2em;
}

.button--arrow,
.button--download {
  .icon {
    transform: translateX(0.25em);
  }
}

.button--arrow-back {
  .icon {
    transform: translateX(-0.25em);
  }
}
