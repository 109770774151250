:root {
  --map-size: calc(var(--c9));
}

.plugin--map {
  padding: var(--margin) var(--p);
}

.map {
  --z-map-marker: 1;
  --z-map-marker-active: 10;
  --z-map-marker-content: 15;
  --z-map-cluster: 3;
  --z-map-cluster-span: 5;

  position: relative;
  width: var(--map-size);
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;

  &__container {
    height: calc(var(--map-size) / 3 * 2);
  }
}

.map__marker__visual,
.map__cluster__visual {
  border-radius: 50%;
  width: 1.25em;
  height: 1.25em;
}

.map__cluster__visual {
  background-color: rgba(0, 0, 0, 0.8);

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: var(--z-map-cluster);

  span {
    font-size: 1.325rem;
    color: var(--sun);
    z-index: var(--z-map-cluster-span);
  }
}

.map__marker__visual {
  border: 0.1em solid var(--black);
  background-color: var(--sun);

  box-shadow: 0.08em 0.08em 0px var(--shadow);

  .active > & {
    border-width: 0.2em;
    width: 1.5em;
    height: 1.5em;
    background-color: unset;
  }

  &:hover {
    cursor: pointer;
  }
}

.map__controls {
  --grid-gap: 1em;
  position: absolute;
  bottom: 1em;
  right: 1em;

  display: flex;
  gap: var(--grid-gap);

  .button {
    border-radius: 50%;
    padding: 0.75em;

    --button-bg: var(--sun);
    --button-color: var(--black);
    --icon-size: 1.75rem;
  }
}
.map__marker {
  position: relative;
  z-index: var(--z-map-marker);
  &.active {
    z-index: var(--z-map-marker-active);
  }
}
.map__marker__content {
  position: absolute;
  display: none;

  font-size: 1.2em;

  left: 120%;
  top: calc(50% - 1em);

  width: max-content;
  max-width: var(--c3);
  background-color: var(--shadow-dark);
  padding: 0.25em 0.5em;
  color: var(--sun);

  z-index: var(--z-map-marker-content);
  .active > & {
    display: block;
  }
}

.map__caption {
  max-width: var(--map-size);
  margin: var(--margin) auto 0 auto;
  padding: 0 var(--p);
}

.map__teasing-text {
  height: 10rem;

  @media (--media-lg) {
    height: 6rem;
  }
}

.map__form {
  --_height: 3.75rem;
  --interact-size: max(14px, 1rem);

  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing);
  align-items: start;
  margin-bottom: var(--spacing);
  min-height: 9.7rem;

  @media (--media-lg) {
    --_height: 3.25rem;
  }
}

.map__form--no-data {
  flex-direction: column;
}

[for="plz"],
[for="inp-amount"] {
  display: block;
}

.map__search {
  width: max(100%, 22rem);

  @media (--media-lg) {
    width: max(40%, 22rem);
  }
}

.map__amount-input,
.map__plz-input {
  font-size: 16px;
  font-size: max(1rem, 16px);
}

.map__plz-input {
  min-width: 22rem;
  width: max(100%, 22rem);

  > div {
    min-height: var(--_height);
    border-color: var(--black);
    border-radius: 0;

    span {
      background-color: var(--black);
    }

    svg {
      fill: var(--black);
      stroke: var(--black);
    }
  }
}

.map__donation-no-commune {
  height: var(--_height);
  margin-top: auto;
}

.map__donation-radio-inputs {
  max-height: 0;
  overflow: hidden;
  animation: reveal 0.75s linear forwards;
}

@keyframes reveal {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 40rem;
  }
}

.map__teasing-text {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: var(--spacing);
}

.map__donation-share-item {
  display: flex;
  margin-bottom: var(--spacing-s);

  input {
    margin-right: var(--spacing-s);
    margin-top: 0.125rem;
  }

  [disabled] {
    cursor: not-allowed;
    color: var(--gray);
  }

  b {
    font-weight: 700;
  }
}

.map__free-amount {
  padding: 0 0.2rem !important;
  width: 6rem;
}

.map__donation-submit {
  @media (--media-lg) {
    margin-top: var(--spacing-s);
  }
}

.map__amount-submit {
  @media (--media-lg) {
    margin-top: 2rem;
  }
}

.button--plz-input {
  font-size: 2rem;
  height: var(--_height);
  border-width: 0.15rem;
  position: relative;
  top: 0.35rem; /* <-- random i know, but it solves it */
}

.map__container {
  outline: 1px solid black;
  outline-offset: -1px;
}

.map__counter {
  display: block;
  padding: 2.75rem 4rem;
  color: var(--map-counter-color);
  background: var(--map-counter-bg);
  @media (--media-lg) {
    max-width: 80%;
  }
  margin: 0 auto;
  text-align: center;
}

.map__disclaimer {
  margin: var(--spacing) 0;
}
