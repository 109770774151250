@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  src: url(../fonts/Montserrat.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  src: url(../fonts/Montserrat.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  font-weight: 900;
  font-style: normal;
  src: url(../fonts/Montserrat.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
}

html {
  font-size: clamp(0.625rem, 1vw, 1rem);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 150%;
  text-rendering: optimizeLegibility;

  @media (--media-lg) {
    font-size: 1.125rem;
  }
}

a[class]:not(.button, .f3cc-button) {
  text-decoration: none;
  color: inherit;
}

h1 {
  font-size: 3.75rem; /*  60px */
  font-weight: 900;
  line-height: 120%;
}

h2 {
  font-size: 3rem; /*  48px; */
  font-weight: 900;
  line-height: 120%;
}

h3 {
  font-size: 2.188rem; /*  35px; */
  font-weight: 900;
  line-height: 120%;
}

h4,
.lead {
  font-size: 2.188rem; /*  35px; */
  font-weight: 500;
  line-height: 120%;
}

h5 {
  font-size: 1.563rem; /*  25px; */
  font-weight: 900;
  line-height: 120%;
}

h6 {
  font-size: 1.563rem; /* 25px; */
  font-weight: 500;
  line-height: 120%;
}

button,
.button {
  font-weight: 700;
  font-size: 1rem;
}

strong,
b {
  font-weight: 900;
}

.prose {
  h1,
  h2,
  h3 {
    max-width: var(--w-title);
    margin-top: 1em;
    margin-bottom: calc(var(--spacing) / 2);
  }

  p,
  .button,
  figure,
  ul,
  ol,
  table,
  h4,
  h5,
  h6 {
    max-width: var(--w-prose);
    margin-bottom: var(--spacing);

    &:not([class]) {
      @media (--media-md) {
        margin-left: calc(2 * var(--p) + var(--c));
      }
    }
  }

  a:not([class]) {
    color: var(--link-color);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    hyphens: auto;
  }
}

.embedded-video > div {
  margin-bottom: var(--spacing);
}

figcaption {
  text-align: center;
  margin: 0.2rem 0;
}

figcaption,
small {
  font-size: 0.8rem;
  /* color: var(--gray); */
}

.map__counter {
  font-weight: 700;
  font-size: 2rem;
  line-height: 120%;
}

.map__disclaimer {
  font-style: italic;
  font-size: 1.1rem;
  line-height: 120%;
}
