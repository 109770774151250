.hero-container {
  height: 100vh;
  background-color: var(--hero-bg);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .portrait,
  .landscape {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .portrait {
    display: none;

    @media (orientation: portrait) {
      display: block;
    }
  }

  .landscape {
    display: none;

    @media (orientation: landscape) {
      display: block;
    }
  }

  .overflow-arrow {
    --icon-color: var(--white);
    --icon-size: 64px;

    position: absolute;
    bottom: 54px;
    text-align: center;

    @media (--media-sm-only) {
      --icon-size: 32px;
      bottom: 27px;
    }
  }
}

.slogan {
  position: absolute;
  top: min(16.5rem, 33%);
  width: 100%;
  max-width: var(--w);

  span {
    max-width: 100%;
    background: var(--hero-bg);
    color: var(--hero-color);
    hyphens: auto;
  }

  /* Tweak for really small devices */
  @media screen and (max-width: 25.875rem) {
    top: min(12.5rem, 33%);
  }
}

.slogan-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-right: auto;
  font-size: 6rem;
  line-height: 1.1;

  /* Tweak for really small devices */
  @media screen and (max-width: 25.875rem) {
    font-size: 5rem;

    [lang="fr"] & {
      font-size: 4rem;
    }
  }
}

html {
  scroll-behavior: smooth;
}

#content-anchor {
  scroll-margin: 6rem;
}
