.supporters {
  --grid-cell: var(--c2);

  @media (--media-md) {
    --grid-cell: var(--c3);
  }

  @media (--media-lg) {
    gap: 2.5rem;
    --grid-cell: var(--c2);
  }
}
