.article-box {
  display: flex;
  flex-direction: column;
  gap: var(--grid-gap);

  padding: var(--spacing);
  background-color: var(--article-teaser-bg);

  &__details {
    display: flex;
    flex-direction: column;
    gap: var(--grid-gap);
  }
}

.arcticle__backlink {
  margin-bottom: 1rem;
}

.article-box__link {
  display: flex;
  justify-content: right;
}

.article__meta {
  display: flex;
  justify-content: space-between;
  margin: var(--p) 0;

  &__info {
    display: flex;
    flex-flow: column;
  }
}

.article__meta__some-links {
  display: flex;
  justify-content: right;
  gap: var(--spacing-s);
}

.article-teaser {
  --grid-gap: 1rem;
  position: relative;

  background-color: var(--article-teaser-bg);
  color: var(--article-teaser-color);

  figcaption,
  h3,
  small {
    color: inherit;
  }

  padding: var(--margin) 0;

  > .container {
    display: flex;
    align-items: stretch;
    gap: var(--spacing);

    flex-wrap: wrap;
    @media (--media-lg) {
      flex-wrap: nowrap;
    }
  }

  &__details,
  &__details__info {
    display: flex;
    flex-direction: column;
    gap: var(--grid-gap);
  }
  &__details {
    justify-content: space-between;
  }

  &__details__link {
    display: flex;
    justify-content: left;
  }

  &__image {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
