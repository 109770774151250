.plugin--wrap .pledge-teaser {
  .pledge-teaser__body {
    color: var(--pledge-color);
  }
}

.pledge-some-links-container {
  display: flex;
  justify-content: space-around;
  .some-links {
    display: flex;
    gap: var(--spacing);
  }
}

.pledge-teaser {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(min(calc(var(--c6) - var(--gap)), 100%), 1fr)
  );
  gap: calc(2 * var(--gap));
}
.pledge-teaser__body {
  h2 {
    margin-bottom: calc(2 * var(--p));
  }
}
.pledge-teaser__counter {
  small {
    font-weight: 900;
    font-size: 0.8em;
    color: var(--gray--text);
  }
  strong {
    margin-right: var(--spacing);
  }
  .button {
    margin-block: calc(2 * var(--p)) 0;
  }
}
.pledge-teaser__counter-box {
  width: min(var(--c5), 100%);
  background-color: var(--white);
  border: 0.2em solid var(--black);
  padding: var(--p) var(--spacing);
}
.progress-bar {
  margin-top: var(--spacing-s);
  background-color: var(--gray--light);
  border-radius: 0.25em;
}
.progress {
  height: 0.5em;
  background-color: var(--pledge-bg);
  margin-block: auto;
  border-radius: 0.25em;
}
.progress.animate {
  animation: progress 1.6s ease-in 1;
}
@keyframes progress {
  from {
    width: 0;
  }
}
