.form {
  font-size: var(--interact-size);

  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
    transition: opacity 0.1s ease-out;
  }

  fieldset[disabled] {
    opacity: 0.7;
  }

  .field {
    margin-bottom: var(--grid-gap);
  }

  small {
    display: block;
  }

  .field > label {
    display: block;
    margin-bottom: calc(0.25 * var(--grid-gap));
  }

  .field > label.required::after {
    content: "*";
  }

  .field > label.error {
    color: var(--errors-fg);
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="number"],
  select,
  textarea {
    font-size: 16px;
    font-size: max(1rem, 16px);
    padding: 0.75rem 0.5rem;
    border-radius: 0;
    border: none;
    &:focus {
      box-shadow: 3px 2px 11px 0 var(--black);
      outline: none;
    }
  }

  input[type="button"] {
    border-radius: 0;
  }

  .widget--radioselect {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }

  .widget--radioselect label {
    margin-left: 0.5rem;
  }
  .widget--radioselect {
    label > input {
      margin-right: 0.5rem;
    }
  }

  .widget--selectdatewidget {
    display: flex;
    flex-flow: row wrap;
    label {
      flex: 1 0 100%;
    }
    select {
      flex: 0 1 min-content;
      &:not(:last-of-type) {
        margin-right: var(--spacing-s);
      }
    }
  }

  .widget--hiddeninput {
    display: none;
  }

  .help {
    line-height: 1.5;
    font-size: 0.8em;
    /* color: var(--gray); */
    margin-top: calc(0.25 * var(--grid-gap));

    /* prevent dobule bottom margins */
    .prose & {
      margin-bottom: 0;
    }
  }

  .errors,
  .errorlist {
    color: var(--errors-fg);
  }

  @media (--media-md) {
    .field-50-50 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: var(--grid-gap);
    }
    .field-25-75 {
      display: grid;
      grid-template-columns: 1fr 3fr;
      gap: var(--grid-gap);
    }
    .field-75-25 {
      display: grid;
      grid-template-columns: 3fr 1fr;
      gap: var(--grid-gap);
    }
  }

  .message {
    font-size: 1.2rem;
    font-weight: 900;
    color: var(--gray);
  }
}

.form--wide-fields {
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="number"],
  select,
  textarea {
    width: 100%;
  }
  input[type="file"] {
    max-width: 100%;
  }
}

.engagement-box .formgroup--submit {
  display: block;
}

.engagement-box .form {
  @media (--media-md) {
    .field-50-50 {
      display: block;
    }
    .field-25-75 {
      display: block;
    }
    .field-75-25 {
      display: block;
    }
  }
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type="checkbox"],
  input[type="radio"] {
    --active: var(--black);
    --active-inner: var(--black);
    --focus: 2px var(--gray);
    --border: var(--gray);
    --border-hover: var(--gray);
    --background: var(--white);

    -webkit-appearance: none;
    -moz-appearance: none;

    font-size: 1.5rem;

    height: 1em;
    width: 1em;

    outline: none;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin: 0;
    cursor: pointer;

    background: var(--b, var(--background));

    &:after {
      content: "";
      opacity: 0;
      display: block;
      position: absolute;
      left: 0px;
      top: 0px;
    }

    &:checked {
      &:after {
        opacity: 1;
      }
    }

    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }
    &:focus {
      box-shadow: 3px 2px 11px 0 var(--black);
      outline: none;
    }

    & + label {
      cursor: pointer;
    }
  }

  input[type="checkbox"] {
    border-radius: 0;
    margin-right: var(--spacing-s);

    &:after {
      width: 0.3125em;
      height: 0.5625em;
      border: 0.125em solid var(--active-inner);
      border-top: 0;
      border-left: 0;
      left: 0.35em;
      top: 0.1875em;
      transform: rotate(var(--r, 20deg));
    }
    &:checked {
      --r: 43deg;
    }
  }

  input[type="radio"] {
    border-radius: 50%;
    &:after {
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background: var(--active-inner);
      transform: scale(0.5);
    }
  }
}
