:root {
  --white: #ffffff;
  --white-bg: #f5f5f5;
  --white--footer: #f8f8f8;
  --black: #121212;

  --gray: #404060;
  --gray--light: #a9a9a9;
  --gray--text: #6f6767;

  --yellow: #fbe122;
  --blue: #3ab5e6;
  --green: #8cc265;
  --orange: #f4af23;
  --pink: #ecb0d1;
  --purple: #ad96dc;

  --yellow--light: #fff5a8;
  --green--light: #c3dfaf;
  --blue--light: #92d6f1;

  --button-color: var(--white);
  --button-bg: var(--black);

  --link-color: var(--black);

  --errors-fg: var(--pink);

  --hero-bg: transparent;
  --hero-color: var(--white);

  --header-bg: var(--yellow);
  --header-color: var(--black);
  --header-button-bg: var(--yellow);
  --header-button-color: var(--black);

  --menu-bg: var(--yellow);
  --menu-button-bg: var(--yellow);
  --menu-button-color: var(--black);
  --mobile-menu-button-bg: var(--black);
  --mobile-menu-button-color: var(--white);

  --footer-bg: var(--black);
  --footer-color: var(--white--footer);

  --testimonials-bg: var(--yellow--light);
  --testimonials-color: var(--black);

  --bg-featured: var(--yellow);
  --color-featured: var(--black);

  --article-teaser-bg: var(--green);

  --products-bg: var(--yellow);

  --engagement-bg: var(--yellow);
  --engagement-color: var(--black);

  --argument-bg: var(--yellow);
  --argument-color: var(--black);
  --argument-index-bg: var(--black);
  --argument-index-color: var(--white);

  --engagement-bg-0: var(--yellow);
  --engagement-color-0: var(--black);

  --engagement-bg-1: var(--blue);
  --engagement-color-1: var(--black);

  --engagement-bg-2: var(--green);
  --engagement-color-2: var(--black);

  --engagement-bg-3: var(--pink);
  --engagement-color-3: var(--black);

  --engagement-bg-4: var(--purple);
  --engagement-color-4: var(--black);

  --pledge-color: var(--black);
  --pledge-bg: var(--yellow);

  --donate-color: var(--black);
  --donate-bg: var(--blue);

  --map-counter-color: var(--white);
  --map-counter-bg: var(--black);
}
