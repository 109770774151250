:root {
  --f3cc-foreground: var(--white);
  --f3cc-background: var(--gray);
  --f3cc-button-foreground: var(--white);
  --f3cc-button-background: var(--black);
  --f3cc-accept-foreground: var(--black);
  --f3cc-accept-background: var(--yellow);
}

.f3cc-button {
  font-weight: 700;
}
