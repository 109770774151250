.gallery {
  --breakout-bottom: 4rem;
  max-width: var(--w-max);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: calc(var(--spacing) + var(--breakout-bottom));

  @media (--media-sm-only) {
    --breakout-bottom: 12rem;
  }
}

.gallery__slides {
  width: 100%;
  aspect-ratio: 16 / 9;
  max-height: calc(100vh - 6em);
}

.gallery__slide {
  display: none;
}

/*lightgallery style-overwrites*/
.gallery .gallery__slides {
  .lg-outer {
    overflow: visible;
  }

  .lg-content {
    overflow: hidden;
  }

  .lg-next,
  .lg-prev {
    font-size: 2rem;
    margin-top: -2rem;
    background-color: transparent;
  }

  .lg-object {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .lg-sub-html {
    font-size: inherit;
    background: var(--yellow);
    color: var(--black);

    width: 24rem;
    min-height: 10rem;
    padding: calc(var(--p) * 0.75) var(--p);
    text-align: left;

    position: absolute;
    bottom: calc(var(--breakout-bottom) * -1);
    right: 12.5%;
    z-index: 2000;

    &:empty {
      display: none;
    }
  }

  .lg-pager-thumb-cont {
    display: none;
  }

  .lg-pager {
    background-color: var(--orange);
    width: 24px;
    border-radius: var(--box-border-radius);
  }
}
