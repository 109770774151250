:root {
  --header-padding: 3rem;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  padding-top: var(--header-padding);
  padding-bottom: var(--header-padding);

  z-index: var(--z-header);

  background-color: transparent;
  color: var(--header-color);

  transition: background-color 0.3s ease-in-out;

  .scrolled &,
  .no-hero & {
    --header-padding: 1rem;
    background-color: var(--header-bg);
  }
  @media (--media-sm-only) {
    --header-padding: 1rem;
    background-color: var(--header-bg);
  }
}

.header-container,
.mobile-menu .toggle-container {
  height: 8.75rem;
  display: flex;
  align-items: center;

  transition: height 0.3s ease-out;

  .scrolled & {
    height: 6rem;
    @media (--media-md) {
      height: 5rem;
    }
  }

  @media (--media-sm-only) {
    height: 5rem;
  }
}

.header-container {
  justify-content: space-between;
  gap: var(--spacing);
  align-items: center;
  max-width: var(--w);
}

.menu-container {
  max-width: var(--w);
}

.header-inner {
  display: none;
  align-items: center;
  padding: 0.5rem 0;
  gap: 2rem;
  justify-content: center;

  @media (--media-sm-only) {
    padding: 0;
    display: inline-flex;
  }
}

.nav {
  --button-color: var(--menu-button-color);
  --button-bg: var(--menu-button-bg);

  .nav-list {
    gap: 1.5rem;
  }

  .nav-list-item {
    a:hover {
      --button-color: var(--menu-button-bg);
      --button-bg: var(--menu-button-color);
    }
  }

  .nav-link {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  }
}

.toggle-container {
  justify-content: right;
  max-height: 56px;
  @media (--media-sm-only) {
    max-height: 38px;
  }
}

header .logo-image {
  max-height: 100%;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.25));
}

.logo-container {
  flex: 1 0 auto;
  height: 100%;
}

.menu-is-open {
  overflow: hidden;
}

.mobile-menu {
  display: none;

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  min-height: 100vh;

  background: var(--menu-bg);
  z-index: var(--z-mobile-menu);

  overflow-y: auto;

  padding-bottom: var(--margin-l);
  padding-top: var(--header-padding);

  .header-inner {
    width: 100%;
    justify-content: flex-end;
  }

  .nav-toggle {
    margin-left: auto;
    height: 100%;
  }

  .nav-list {
    gap: var(--spacing);
  }

  .nav-list-item {
    --button-color: var(--mobile-menu-button-color);
    --button-bg: var(--mobile-menu-button-bg);
    box-shadow: none;
  }

  .button--menu-toggle .icon {
    box-shadow: none;
  }
}

.cta-container {
  flex: 0 1 auto;
  margin-left: auto;
}

.menu-is-open .mobile-menu {
  display: block;
}

.nav-toggle {
  .button.button--inverted {
    border: none;
    &:hover,
    &:focus-visible {
      background-color: var(--button-bg);
    }
  }
}

.main-nav {
  .nav-list {
    justify-content: right;
  }
  .nav-list-item {
    padding: 0.5em 0;
  }
}

.nav-list {
  display: flex;
  gap: var(--spacing);
  align-items: center;
  flex-wrap: wrap;
  @media screen and (max-height: 800px) {
    gap: 1em;
  }
}

.mobile-language-nav {
  font-size: 0.8em;

  .nav-list {
    margin-top: var(--margin-m);
    justify-content: center;
  }
  .nav-list-item {
    padding: 0.5em 0;
  }
}

.language-nav {
  .nav-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    position: relative;
    top: 0.5rem;
    gap: 0.5rem;

    height: 0px;
  }

  .nav-list-item {
    --button-color: var(--menu-button-color);
    --button-bg: var(--menu-button-bg);
    grid-row: 1;
    grid-column: 1;

    :hover {
      border-color: var(--menu-button-color);
    }
  }

  .nav-list-item:not(.active) {
    opacity: 0;
  }

  .language-nav-toggle {
    --button-color: var(--menu-button-bg);
    --button-bg: var(--menu-button-color);
    z-index: 10;
  }

  .nav-link {
    display: block;
    text-align: center;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  }

  &.open .nav-list-item {
    grid-row: unset;
    opacity: 1;
  }
}

.mobile-nav {
  font-size: 1.33rem;

  .nav-list {
    flex-direction: column;
  }
}

.home:not(.scrolled) .header-container {
  align-items: flex-start;

  @media (--media-sm-only) {
    align-items: center;
  }
}

/* .scrolled.home .logo-image {
  opacity: 1;
} */

.nav,
.language-nav {
  @media (--media-sm-only) {
    display: none;
  }
}
