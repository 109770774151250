.plugin--donate {
  background-color: var(--donate-bg);
  color: var(--donate-color);
}

.donate-cta {
  max-width: var(--w-wrap);

  display: flex;
  gap: var(--spacing);
  flex-flow: column nowrap;

  /* @media (--media-md) {
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
  } */

  &__info {
    max-width: var(--w-prose);

    display: flex;
    gap: var(--spacing);
    flex-flow: column nowrap;
  }

  &__button {
    margin-left: auto;
  }
}

#payrexx-embed {
  width: 100%;
  height: 1400px;
  border: none;

  @media (--media-sm-only) {
    height: 2000px;
  }
}
