.faq__group {
  margin-top: var(--spacing);
}

.faq__details {
  display: block;
}

h2 + .faq__details,
.faq__details:first-of-type {
  .faq__summary {
    border-top: none;
  }
}

h4.faq__question {
  margin: 0 var(--icon-size) 0 0;
  text-align: left;
}

.faq__summary {
  padding: 1em 0;
  border-top: 1px solid var(--black);
  position: relative;
}

.expand-icon__container {
  --icon-size: 2em;

  position: absolute;
  top: calc((100% - var(--icon-size)) / 2);
  right: 0;

  transition: opacity 0.3s ease-out;
}
.expand-more {
  opacity: 1;
}
.expand-less {
  opacity: 0;
}
details[open] .expand-icon__container.expand-more {
  opacity: 0;
}
details[open] .expand-icon__container.expand-less {
  opacity: 1;
}
